import { FC, memo } from 'react'
import './ProductImageDisplay.css'

import type { IMedia } from 'typings/shopApi'

type PIDProps = {
  images: IMedia[]
}

const ProductImageDisplay: FC<PIDProps> = memo(({ images }) => {
  return <img className='block h-60 w-60' src={images[0].url} alt='product image' />
})

ProductImageDisplay.displayName = 'ProductImageDisplay'

export default ProductImageDisplay
